import React, { PureComponent } from 'react';
import { Link, navigate } from '@reach/router';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import BrowseNavigation from '../../../components/BrowseNavigation/BrowseNavigation';
import MainSearch from '../../../components/MainSearch/MainSearch';
import Arrow from '../../../components/Arrow/Arrow';
import { showMyAccountPage } from '../../../services/navigation/navigation.service';
import { isUserLoggedIn } from '../../../services/token-service/token.service';
import {
  routes,
} from '../../../services/navigation/navigation.service.routes';

import { getCookie, isWeb, matchMediaRule, openNewBrowserTab } from '../../../services/util-service/util.service';

import TimTabs from '../../../components/TimNav/TimTabs';
import TimVideoVaultSlider from '../../../components/TimNav/TimVideoVaultSlider.js';
import TimVideoVaultSliderLoggedIn from '../../../components/TimNav/TimVideoVaultSliderLoggedIn.js';
import WatchMoviesNav from '../../../components/TimNav/WatchMoviesSlider.js';
import TimNewsSlider from '../../../components/TimNav/TimNewsSlider.js';
import BuyMoviesSlider from '../../../components/TimNav/BuyMoviesSlider.js';
import '../../../components/TimNav/TimTabs.scss';
import { ReactComponent as AgeVerifiedIcon } from '../../../images/ageVerifiedIcon.svg';

import { ReactComponent as TimSkull } from '../../../images/tim-skull-86x60.svg';

import { SignInController } from '../../../controllers/sign-in-controller/sign-in.controller';

import { links } from '../../../links';

import './MainDashboardHeaderTim.scss';
import {
  RenderConditionalComponent,
  whiteLabelPropertyID,
  PROPERTY_ID,
} from '../../../components/RenderConditionalComponent/RenderConditionalComponent';

// import ApplicationLogo from "../../../components/ApplicationLogo/ApplicationLogo";
import UserAction from '../../../components/UserAction/UserAction';


class MainDashboardHeaderTim extends PureComponent {
  contentScrollListenerSet = false;

  previousLocation;

  state = {
    desktopSearchVisible: false,
    searchVisible: false,
    mobileNavVisible: false,
    userStateChange: undefined,
  };

  subscriptions = {};

  ageVerContentRef = React.createRef(null);
  ageVerVisible = React.createRef(null);

  ageVerifiedClickOutside = (event) => {
    if (isWeb()) return;
    if (this.ageVerContentRef.current) {
      const elem = event.target;

      if (elem.classList.contains('AgeVerifiedContainer')) {
        this.ageVerVisible.current = !this.ageVerVisible.current;
        this.ageVerContentRef.current.style.display = this.ageVerVisible.current ? 'block' : 'none';
      } else {
        this.ageVerContentRef.current.style.display = 'none';
        this.ageVerVisible.current = false;
      }
    }
  };

  componentDidMount() {
    document.addEventListener('click', this.ageVerifiedClickOutside);
  }

  componentWillUnmount() {
    for (const key in this.subscriptions) {
      this.subscriptions[key].unsubscribe();
    }
    if (this.contentScrollListenerSet) {
      this.getContentRouter()?.removeEventListener('scroll', this.onUserScroll);
    }

    document.removeEventListener('click', this.ageVerifiedClickOutside);
  }

  checkIfTargetIsSearchElement = (mobileSearch, target) => {
    let isSearchElement = mobileSearch.className === target.className;
    if (!isSearchElement && target.parentElement) {
      isSearchElement = this.checkIfTargetIsSearchElement(mobileSearch, target.parentElement);
    }

    return isSearchElement;
  };

  getContentRouter = () => {
    const mainContent = document.getElementsByClassName('MainContainer')[0];
    return mainContent.childNodes[1];
  };

  login = () => {
    SignInController.showSignInDialog();
  };

  onMainDashboardClick = (event) => {
    const { searchVisible } = this.state;
    const mobileSearch = document.getElementsByClassName('MobileSearchWrapper')[0];
    if (mobileSearch) {
      if (event.target.className.indexOf('SearchResultItem') === -1) {
        const isSearchElement = this.checkIfTargetIsSearchElement(mobileSearch, event.target);
        if (!isSearchElement) {
          const toggleSearch = event.target.classList.contains('fa-search') || searchVisible ? false : true;
          this.setSearchVisible(toggleSearch);
        }
      } else {
        this.setSearchVisible(false);
      }
    } else {
      const searchVisible = event.target.classList.contains('fa-search');
      this.setSearchVisible(searchVisible);
    }
  };

  onLogoClick = (event) => {
    const { onLogoClick } = this.props;
    if (SignInController.isSignInDisplayed()) {
      SignInController.closeSignInDialog();
    }
    onLogoClick(event);
  };

  onUserScroll = () => {
    if (this.state.searchVisible) {
      this.setSearchVisible(false);
    }
  };

  onUserStateChange = () => {
    this.setState({
      userStateChange: new Date().getTime(),
    });
  };

  renderMobileMenu = () => {
    const toggleMobileMenu = () => {
      this.setState((prevState) => ({
        mobileNavVisible: !prevState.mobileNavVisible,
      }));
    };

    return <i className="MobileMenuBtn fa fa-bars" onClick={toggleMobileMenu} />;
  };

  renderMobileSearch = () => {
    let view = null;
    if (!isWeb()) {
      const { searchVisible } = this.state;
      if (searchVisible) {
        view = (
          <div className="MobileSearchWrapper">
            <div className="MobileSearchWrapperInner">{this.renderSearchEl()}</div>
          </div>
        );
      }
    }
    return view;
  };

  renderMobileSearchTrigger = () => {
    return (
      <div className="searchButton MobileSearchBtn" onClick={this.toggleMobileSearch}>
        <i className="fa fa-search" />
      </div>
    );
  };

  toggleSearchFn = (newState) => {
    this.setState({
      desktopSearchVisible: !newState,
      searchVisible: !newState,
    });
  };

  renderSearchEl = () => {
    const { handleSearchDropdownValue } = this.props;
    return (
      <MainSearch
        toggleSearchFn={this.toggleSearchFn}
        handleSearchDropdownValue={handleSearchDropdownValue}
      />
    );
  };

  renderSecondaryNavigation = () => {
    const { t, starsPageEnabled } = this.props;
    const { desktopSearchVisible } = this.state;
    const navCls = 'NavBar-Btn';
    const headerOptions = (
      <div className="HeaderOptions">
        {!desktopSearchVisible ? (
          <>
            <BrowseNavigation
              className={navCls}
              starsPageEnabled={starsPageEnabled}
              baseText={t('BrowseNavigation.browse')}
            />
            <RenderConditionalComponent
              ids={[PROPERTY_ID.nakedSword, PROPERTY_ID.guerillaPorn, PROPERTY_ID.trentonDucati]}
            >
              <Link className={navCls} to={routes.justAdded} data-text={t('MainDashboardHeader.justAdded')}>
                {t('MainDashboardHeader.justAdded')}
              </Link>
            </RenderConditionalComponent>

            <RenderConditionalComponent ids={[PROPERTY_ID.huntForMen]}>
              <a className={navCls} href={links.zine} rel="noopener noreferrer" key="zine" target="_blank">
                HUNT eZine
              </a>
            </RenderConditionalComponent>

            <RenderConditionalComponent ids={[PROPERTY_ID.guerillaPorn, PROPERTY_ID.trentonDucati]}>
              <a
                className={navCls}
                href={links.live}
                rel="noopener noreferrer"
                target="_blank"
                data-text={t('MainDashboardHeader.live')}
              >
                {t('MainDashboardHeader.live')}
              </a>
            </RenderConditionalComponent>
          </>
        ) : (
          <i className="HeaderNavCollapseIndicator fas fa-bars" />
        )}
        {this.renderSearchEl()}
      </div>
    );

    return headerOptions;
  };
  renderSeparator = () => <div className="Separator">|</div>;

  renderUserActions = () => {
    const userLoggedIn = isUserLoggedIn();
    const { t } = this.props;
    return (
      <div className="UserActions">
        {userLoggedIn ? (
          <UserAction text={t('MainDashboardHeader.signOut')} />
        ) : (
          <UserAction
            text={t('MainDashboardHeader.joinNow')}
            onClick={() => navigate('/join')}
          />
        )}
        {this.renderSeparator()}
        {userLoggedIn ? (
          <UserAction text={t('MainDashboardHeader.myAccount')} onClick={this.showMyAccount} />
        ) : (
          <div onClick={this.login} className="LoginWrapper">
            <UserAction text={t('MainDashboardHeader.login')} />
            <Arrow />
          </div>
        )}
      </div>
    );
  };

  renderLoggedOutOrInSlider = () => {
    const userLoggedIn = isUserLoggedIn();
    return <div>{userLoggedIn ? <TimVideoVaultSliderLoggedIn /> : <TimVideoVaultSlider />}</div>;
  };

  setSearchVisible = (searchVisible) => {
    this.setState({ searchVisible }, this.toggleScrollListener);
  };

  showMyAccount = () => {
    showMyAccountPage();
  };

  showTimSkullLink = () => {
    openNewBrowserTab(links.timmain);
  };

  showTimLoginLink = () => {
    openNewBrowserTab(links.timlogin);
  };

  toggleMobileSearch = () => {
    if (SignInController.isSignInDisplayed()) {
      SignInController.closeSignInDialog();
    }

    if (matchMediaRule('max-width: 960px')) {
      this.setState((prevState) => {
        return {
          searchVisible: !prevState.searchVisible,
        };
      });
    }
  };

  toggleScrollListener = () => {
    if (!this.contentScrollListenerSet) {
      this.contentScrollListenerSet = true;
      const contentRouter = this.getContentRouter();
      contentRouter?.addEventListener('scroll', this.onUserScroll);
    }
  };

  renderBlurBackgroundMobile = () => {
    const { searchVisible } = this.state;
    if (!searchVisible || isWeb()) {
      return null;
    }
    return <div className="BackdropFilterMobile"></div>;
  };

  renderAgeVerIcon = () => {
    let bavCookie = getCookie('bav');
    if (!bavCookie) return null;

    try {
      bavCookie = decodeURIComponent(bavCookie);
      bavCookie = window.atob(bavCookie);
    } catch (err) {
      console.error('Decoding bav failed:', err);
    }
    const bavToLocalFormat = new Date(bavCookie * 1000).toLocaleString(); // timestamp
    const validBavDate = bavToLocalFormat !== 'Invalid Date' ? bavToLocalFormat : '';

    return (
      <span className="AgeVerifiedContainer">
        <AgeVerifiedIcon />
        <div className="AgeVerifiedContent" ref={this.ageVerContentRef}>
          <div>Age verification successful.</div>
          <div>Verification valid until:</div>
          <div>{validBavDate}</div>
        </div>
      </span>
    );
  };

  render() {
    const { mobileNavVisible } = this.state;
    const { className, primaryNavigation } = this.props;
    const classes = ['MainDashboardHeader PrimaryHeader Primary'];
    const nakedSwordStyles =
      whiteLabelPropertyID === PROPERTY_ID.nakedSword || whiteLabelPropertyID === PROPERTY_ID.guerillaPorn
        ? 'nakedSword-styles'
        : '';

    if (primaryNavigation) {
      classes.push('Primary');
    }
    if (className) {
      classes.push(className);
    }
    return (
      <div className={`TimNavContainer ${mobileNavVisible ? 'Visible' : ''}`}>
        <div className="column skull TimSkull" onClick={this.showTimSkullLink}>
          <TimSkull />
        </div>

        {/*}  <div className="column videovaultlogo TimLogo"><ApplicationLogo /></div> */}

        <div className="timnavmain TabsWrap">
          <TimTabs>
            <div className="TimVideoVaultLogo" navLabel="Video Vault">
              {this.renderLoggedOutOrInSlider()}
            </div>

            <div navLabel="Buy Movies">
              <BuyMoviesSlider />
            </div>

            <div navLabel="Watch Movies">
              <WatchMoviesNav />
            </div>

            <div navLabel="Toys &amp; Gear">
              <TimNewsSlider />
            </div>
            <div navLabel="Search">{this.renderSecondaryNavigation()}</div>
          </TimTabs>
        </div>

        <div className={classes.join(' ')}>
          <div className={`Inner ${nakedSwordStyles}`}>
            <div className="Section">
              {this.renderMobileSearchTrigger()}
              {this.renderMobileMenu()}
            </div>
          </div>
          {this.renderMobileSearch()}
        </div>

        {this.renderAgeVerIcon()}
      </div>
    );
  }
}

MainDashboardHeaderTim.propTypes = {
  className: PropTypes.string,
  primaryNavigation: PropTypes.bool,
  onLogoClick: PropTypes.func,
  toggleMobileMenu: PropTypes.func,
  starsPageEnabled: PropTypes.number,
  handleSearchDropdownValue: PropTypes.func,
};

export default withTranslation()(MainDashboardHeaderTim);