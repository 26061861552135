import React from 'react';

import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../context/AuthContext';
import { showJoinNowPage } from '../../../services/navigation/navigation.service';
import { isWeb } from '../../../services/util-service/util.service';

import './MobileUserActions.scss';
import { openNewBrowserTab } from '../../../services/util-service/util.service';
import { links } from '../../../links';

const MobileUserActions = () => {
  const { t } = useTranslation();
  const { userLoggedIn } = useAuthContext();

  const showTimLoginLink = () => {
    openNewBrowserTab(links.timlogin);
  };

  if (userLoggedIn || isWeb()) return null;

  return (
    <div className="MobileUserActions">
      <span onClick={showJoinNowPage}>{t('MobileUserActions.joinNow')}</span>
      <span className="Separator" />
      <span onClick={showTimLoginLink}>{t('MobileUserActions.login')}</span>
    </div>
  );
};

export default MobileUserActions;
