import React, { useState, useEffect } from 'react';
import { globalHistory, Location, Router } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import {
  getFavoritesPageRoute,
  getSignInPageRoute,
  routes,
} from '../../services/navigation/navigation.service.routes';
import { isWeb, setContentScrolltop } from '../../services/util-service/util.service';
import { SignInController } from '../../controllers/sign-in-controller/sign-in.controller';
import { getNatsData } from '../../services/nats-service/nats.service';
import { useWLconfigContext } from '../../context/WLConfigContext.js';
import { useAuthContext } from '../../context/AuthContext.js';
import { useGAanalytics } from '../../hook/useGtag.js';

import MobileSecondaryNavigation from '../../components/mobile/MobileSecondaryNavigation/MobileSecondaryNavigation';
import SideMenu from '../../components/mobile/SideMenu/SideMenu';
import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import BannerSetViewFunc from '../../components/BannerSetView/BannerSetViewFunc.jsx';
import { LoadingText } from '../../components/LoadingMask/LoadingMask.js';
import AgeWarningOverlay from '../../components/AgeWarningOverlay/AgeWarningOverlay.js';
import TimFooter from '../../components/TimFooter/TimFooter.js';
import ModalContainer from '../../components/ModalContainer/ModalContainer';
import Notifications from '../../components/Notifications/Notifications';
import MobileUserActions from '../../components/mobile/MobileUserActions/MobileUserActions';
import NewsletterConfirmPage from '../../components/NewsletterSignUp/NewsletterConfirm.js';

import MainDashboardHeaderTim from './MainDashboardHeader/MainDashboardHeaderTim';
import MainDashboardView from './MainDashboardView/MainDashboardView';
import AvsPage from '../AvsPage/AvsPage.js';
import StreamTest from '../StreamTest/StreamTest.js';
import DirectorPage from './DirectorPage/DirectorPage';
import JustAddedComponent from './MainDashboardView/JustAddedComponent/JustAddedComponent';
import SearchResultsPage from './SearchResultsPage/SearchResultsPage';
import MyAccountPage from './MyAccountPage/MyAccountPage';
import MemberDeals from './MemberDealsPage/MemberDealsPage';
import TopTen from './TopTen/TopTen';
import OriginalsPage from './OriginalsPage/OriginalsPage';
import ThemeDetailsPage from './ThemesPage/ThemeDetailsPage/ThemeDetailsPage';
import MovieDetailsPage from './MovieDetailsPage/MovieDetailsPage';
import StudioDetailsPage from './StudiosPage/StudioDetailsPage/StudioDetailsPage';
import SeriesPage from './SeriesPage/SeriesPage';
import JustAddedPage from './JustAddedPage/JustAddedPage';
import StarsPage from './StarsPage/StarsPage';
import StudiosPage from './StudiosPage/StudiosPage';
import ThemesPage from './ThemesPage/ThemesPage';
import PlaylistsPage from './PlaylistsPage/PlaylistsPage';
import StreamBlocked from './StreamBlockedPage/StreamBlocked';
import MemberPWReminder from '../../components/MemberPWReminder/MemberPWReminder.js';
import Streamate from './Streamate/Streamate.js';
import Live from './Live/Live.js';
import SpecialOffers from '../SpecialOffers/SpecialOffers';
import FavoritesPage from './FavoritesPage/FavoritesPage';
import UserPlaylistsPage from './UserPlaylistsPage/UserPlaylistsPage';
import SignInPage from './SignInPage/SignInPage';
import ViewingHistoryPage from './ViewingHistoryPage/ViewingHistoryPage';
import NotFound from '../NotFound/NotFound';
import SSOPage from './SSOPage/SSOPage.js';
import WeekendMain from './WeekendsPage/WeekendMain.js';
import FreeMain from './SpecialPromos/Free/FreeMain.js';
import UserInfoPages from './UserInfoPages/UserInfoPages.js';

import './MainDashboard.scss';
import '../../styles/livechat_buttons.scss';

// temporary weekends promo pages
const weekendsUrls = [
  routes.weekends,
  routes.mask4mask,
  routes.misspineapple2021,
  routes.mrman2020,
  routes.party20th,
  routes.pride2020
];

// temporary free promo pages
const freeUrls = [
  routes.free,
  routes.freerideordiebonusvideo,
  routes.freerideordiebonusbehindscenes,
  routes.freerideordiebonushaditcoming,
  routes.freerideordiebonusmeetthewarden
];

const userInfoUrls = [ // /privacy, /help, /tos, /compliance
  routes.help,
  routes.termsOfUse,
  routes.privacyPolicy,
  routes.compliance
];

export default function MainDashboard() {
  const { t } = useTranslation();
  const pathName = window.location?.pathname;
  const {
    wl_configLoading,
    showLoader,
    variables: {
      REACT_APP_SITE_NAME,
      REACT_APP_SITE_TITLE,
      REACT_APP_SITE_DESCRIPTION,
      REACT_APP_JOIN_NOW_URL,
      REACT_APP_UNIVERSAL_GA_ID,
      REACT_APP_WHITELABEL_GTM_ID,
      REACT_APP_ENV_GA_MEASUREMENT_ID
    } = {}
  } = useWLconfigContext();

  const { userLoggedIn } = useAuthContext();

  const [state, setState] = useState({
    sideMenuVisible: false,
    searchDropdownValue: '',
    whitelabelNatsCode: undefined
  });

  const {
    sideMenuVisible,
    searchDropdownValue
  } = state;

  useGAanalytics({
    loggedIn: userLoggedIn,
    loading: wl_configLoading,
    GA_id: REACT_APP_ENV_GA_MEASUREMENT_ID,
    GA_uni_id: REACT_APP_UNIVERSAL_GA_ID,
    GTM_id: REACT_APP_WHITELABEL_GTM_ID
  });

  useEffect(() => {
    if (wl_configLoading) return; // prevents double requests for Nats

    const { joinUrl } = getNatsData(REACT_APP_JOIN_NOW_URL);

    const redirectToJoin = () => {
      const { pathname } = window.location;

      if (pathname.includes('/join')) {
        showLoader(true);
        window.location.href = joinUrl;
      }
    };

    globalHistory.listen(({ action, location }) => {
      setContentScrolltop(); // on route change scroll to top
      redirectToJoin(); // on navigate to /join
    });

    redirectToJoin();

  }, [wl_configLoading, showLoader, REACT_APP_JOIN_NOW_URL]);

  // needed for SearchResultsPage
  const handleSearchDropdownValue = (value) => {
    setState(prevState => ({ ...prevState, searchDropdownValue: value }));
  };

  const onMainDashboardClick = () => {
    if (sideMenuVisible) {
      setState(prevState => ({ ...prevState, sideMenuVisible: false }));
    }
  };

  const toggleMobileMenu = () => {
    if (SignInController.isSignInDisplayed()) {
      SignInController.closeSignInDialog();
    }
    setState(prevState => ({ ...prevState, sideMenuVisible: !prevState.sideMenuVisible }));
  };

  const renderMemberUpsellBottomBanner = () => {
    return (pathName.includes(routes.myAccount) || pathName.includes(routes.help)) && isWeb() && userLoggedIn ? (
      <BannerSetViewFunc bannerName="memberUpsellBottom" />
    ) : null;
  };

  const renderMETAinfo = () => {
    return (
      <Helmet>
        <title>{REACT_APP_SITE_TITLE}</title>
        <meta name="description" content={REACT_APP_SITE_DESCRIPTION} />
      </Helmet>
    )
  };

  if (pathName.includes(`/${routes.ageVerificationLanding}`)) {
    return (
      <>
        {renderMETAinfo()}
        <AvsPage />
      </>
    )
  }

  if (pathName === `/${routes.sso}`) {
    return <SSOPage />;
  }

  if (pathName === `/${routes.diag}`) {
    return <StreamTest />;
  }

  if (wl_configLoading) return (
    <>
      {renderMETAinfo()}
      <LoadingText />
    </>
  );

  return (
    <ErrorBoundary siteName={REACT_APP_SITE_NAME}>
      {renderMETAinfo()}

      <div className="MainDashboard" onClick={onMainDashboardClick}>
        <AgeWarningOverlay />
        <MainDashboardHeaderTim
          toggleMobileMenu={toggleMobileMenu}
          handleSearchDropdownValue={handleSearchDropdownValue}
        />
        <div className="MainContent">
          <SideMenu className={sideMenuVisible ? 'Visible' : ''} />
          <Location>
            {({ location }) => (
              <>
                <JustAddedComponent />
                <Router primary={false} location={location}>
                  <MainDashboardView path={routes.root} />
                  <DirectorPage path={routes.directorInfo} />
                  <FavoritesPage path={getFavoritesPageRoute()} />
                  <JustAddedPage path={routes.justAdded} />
                  <JustAddedPage path={routes.mostWatched} />
                  <UserPlaylistsPage path={routes.userPlaylists} />
                  <SignInPage path={getSignInPageRoute()} />
                  <MemberDeals path={routes.memberDeals} />
                  <MovieDetailsPage path={routes.movieScenes} />
                  <MovieDetailsPage path={routes.movieInfo} />
                  <MyAccountPage path={routes.myAccount} />
                  <NewsletterConfirmPage path={routes.newsletterconfirm} />
                  <OriginalsPage path={routes.originals} />
                  <PlaylistsPage path={routes.playlistsAnything} />
                  <StarsPage path={routes.starsAnything} />
                  <StudiosPage path={routes.studios} />
                  <StudioDetailsPage path={routes.studiosInfo} />
                  <TopTen path={routes.top10} />
                  <SearchResultsPage path={routes.search} searchDropdownValue={searchDropdownValue} />
                  <SeriesPage path={routes.seriesInfo} />
                  <ThemesPage path={routes.themes} />
                  <ThemeDetailsPage path={routes.themeInfo} />
                  <ThemeDetailsPage path={routes.sexActInfo} />
                  <ViewingHistoryPage path={routes.viewingHistory} />
                  <StreamBlocked path={routes.streamblocked} />
                  <MemberPWReminder path={routes.forgotpasswordpage} />
                  {weekendsUrls.map(item => <WeekendMain path={item} key={item} />)}
                  {freeUrls.map(item => <FreeMain path={item} key={item} />)}
                  {userInfoUrls.map(item => <UserInfoPages path={item} key={item} />)}
                  <SpecialOffers path={routes.specialOffers} />
                  <Streamate path={routes.streamate} />
                  <Live path={routes.live} />
                  <NotFound
                    default
                    linkWrapperClass="Exclamation"
                    iconClass="fas fa-exclamation"
                    title={t('MainDashboard.notFoundTitle')}
                    subtitleRowOne={t('MainDashboard.notFoundRowOne')}
                  />
                </Router>
                <TimFooter />
                {renderMemberUpsellBottomBanner()}
              </>
            )}
          </Location>
          <div className='MobileBottomUserButtons'>
            <MobileSecondaryNavigation />
            <MobileUserActions />
          </div>
        </div>

        <ModalContainer />
        <Notifications />
      </div>
    </ErrorBoundary>
  );
}