import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthContext } from '../../context/AuthContext';
import BoxCoverPlayerDetailsComponent from '../../components/BoxCoverPlayerDetailsComponent/BoxCoverPlayerDetailsComponent';
import ModalContainer from '../../components/ModalContainer/ModalContainer';
import Notifications from '../../components/Notifications/Notifications';

import './StreamTest.scss';

/*
This component is used for diagnostics outside of the age verification process, so we can determine if the WL is working or not
 */

const StreamTest = () => {
  const { t } = useTranslation();
  const { userLoggedIn, logout } = useAuthContext();

  useEffect(() => {
    const html = document.documentElement;
    if (html) {
      html.style.overflow = 'auto';
      html.scrollTop = 0;
    }

    const onLinkClick = (e) => {
      const elem = e.target || {};
      if (elem.tagName?.toLowerCase() === 'a') {
        window.location.reload();
      }
    }

    document.addEventListener('click', onLinkClick);

    return () => document.removeEventListener('click', onLinkClick);

  }, []);

  const renderUserActions = () => {
    return (
      <div className="AccountContainer">
        {userLoggedIn ? (
          <span onClick={() => {
            logout(() => window.location.reload());
          }}>{t('MainDashboardHeader.signOut')}</span>
        ) : (
          <a href="https://treasureislandmedia.com/members/member" class="AcctionBtn" rel="noopener noreferrer">
            {t('MainDashboardHeader.login')} <i className="fa fa-chevron-down"></i>
          </a>
        )}
      </div>
    );
  };

  return (
    <div className="StreamTest">
      {renderUserActions()}
      <BoxCoverPlayerDetailsComponent movieId="161057" omitAds omitPreviewsCount />
      <ModalContainer />
      <Notifications />
    </div>
  );
};

StreamTest.displayName = 'StreamTest';

export default StreamTest;
