import React, { Component } from 'react';
import './ErrorBoundary.scss';

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error) {
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="ErrorBoundary">
          <div className="LogoWrapper"></div>
          <div className="TextWrapper">
            <p>Sorry, we're temporarily down for maintenance.</p>
            <p>We should be back online shortly.</p>
          </div>
          <span className="SiteName">{this.props.siteName || window.location.hostname}</span>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.displayName = 'ErrorBoundary';

export const ErrorMessage = () => {
  return (
    <div className="ErrorMessage">
      <span>Oops! Something went wrong...</span>
    </div>
  );
};
