import React, { useEffect } from 'react';

function TimNewsSlider() {
  useEffect(() => {
    window.location.href = 'https://toysfromtim.com';
  }, []);

  return <div></div>;
}

export default TimNewsSlider;