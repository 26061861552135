import React, { useEffect } from 'react';

function WatchMoviesNav() {
  useEffect(() => {
    window.location.href = 'https://treasureislandmedia.com';
  }, []);

  return <div></div>;
}

export default WatchMoviesNav;




/*
import React from 'react';
import Slider from 'react-slick';
import useUserStateChange from '../../hook/useUserStateChange';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function WatchMoviesNav() {
  const [isLoggedIn] = useUserStateChange();

  const sliderSettings = {
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    touchThreshold: 100,
    infinite: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const WatchMoviesNav = [
    {
      hrefLink: 'https://treasureislandmedia.com/scenes',
      hrefText: 'SCENES',
    },
    {
      hrefLink: 'https://treasureislandmedia.com/movies',
      hrefText: 'MOVIES',
    },
    {
      hrefLink: 'https://men.treasureislandmedia.com/men',
      hrefText: 'MEN',
    },
    {
      hrefLink: 'https://classics.treasureislandmedia.com/',
      hrefText: 'CLASSICS',
    },
    {
      hrefLink: 'https://www.timvideovault.com/',
      hrefText: 'VIDEO VAULT',
    },
    {
      hrefLink: 'https://classics.treasureislandmedia.com/favorites',
      hrefText: 'FAVORITES',
    },
    {
      hrefLink: 'https://treasureislandmedia.com/members/member',
      hrefText: isLoggedIn ? 'DASHBOARD' : 'LOGIN',
    },
  ];

  return (
    <div className="content">
      <Slider {...sliderSettings}>
        {WatchMoviesNav.map((navlink, index) => (
          <div key={index} className="navlink">
            <div className="text-info">
              <a href={navlink.hrefLink} className="nav-sub-link" rel="noopener noreferrer">
                <span>{navlink.hrefText}</span>
              </a>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
*/