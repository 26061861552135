export const ROUTES = {
  compliance: 'compliance',
  director: 'director',
  directorInfo: 'director/:directorId/:directorName',
  favorites: 'my/favorites',
  help: 'help',
  justAdded: '/just-added',
  userPlaylists: 'my/playlists',
  signin: 'signin',
  sso: 'sso',
  mostWatched: '/most-watched',
  movie: 'movies',
  movieInfo: 'movies/:movieId/:movieName',
  movieScenes: 'movies/:movieId/:movieName/scene/:sceneIndex',
  myAccount: 'my/account',
  memberDeals: 'member-deals',
  newsletter: 'newsletter',
  newsletterconfirm: 'newsletterconfirm',
  originals: 'nsoriginals',
  playlists: '/playlists',
  playlistsAnything: '/playlists/*',
  playlistMovies: '/playlists/:moviePlaylistId/movies/:playlistName',
  playlistScenes: '/playlists/:scenePlaylistId/scenes/:playlistName',
  playlistCustom: '/playlists/custom/:customPlaylistId/:playlistName',
  privacyPolicy: 'privacy',
  root: '/',
  sexAct: 'scenes/for/sexact',
  sexActInfo: 'scenes/for/sexact/:name',
  stars: '/stars',
  starsAnything: '/stars/*',
  starInfo: '/stars/:starId/:starName',
  starByLetter: '/stars/index/:letter',
  studios: 'studios',
  studiosInfo: 'studios/:studioId/:studioName',
  specialOffers: 'specialoffers',
  series: 'series',
  seriesInfo: 'series/:seriesId',
  termsOfUse: 'tos',
  theme: 'theme',
  themeInfo: 'theme/:name',
  themes: 'themes',
  top10: 'top-10',
  viewingHistory: 'my/viewing-history',
  streamblocked: 'stream-blocked',
  forgotpasswordpage: 'forgotpasswordpage',
  streamate: 'streamate',
  ageVerificationLanding: 'age-verification',
  ageVerificationSuccess: '/age-verification/success',
  ageVerificationCancel: '/age-verification/cancel',
  ageVerificationError: '/age-verification/error',
  ageVerificationFaq: '/age-verification/help',
  ageVerificationTos: '/age-verification/tos',
  ageVerificationPrivacy: '/age-verification/privacy',
  live: 'live',
  weekends: 'weekends',
  mask4mask: 'mask4mask',
  misspineapple2021: 'misspineapple2021',
  mrman2020: 'mrman2020',
  party20th: 'party20th',
  pride2020: 'pride2020',
  free: 'free',
  freerideordiebonusvideo: 'freerideordiebonusvideo',
  freerideordiebonusbehindscenes: 'freerideordiebonusbehindscenes',
  freerideordiebonushaditcoming: 'freerideordiebonushaditcoming',
  freerideordiebonusmeetthewarden: 'freerideordiebonusmeetthewarden',
  search: 'search',
  join: 'join',
  trentonducatioriginals: 'trentonducatioriginals',
  diag: 'diag',
};